@font-face {
  font-family: 'dimigoincon';
  src: url('../font/dimigoincon.eot?25802755');
  src: url('../font/dimigoincon.eot?25802755#iefix') format('embedded-opentype'),
       url('../font/dimigoincon.woff2?25802755') format('woff2'),
       url('../font/dimigoincon.woff?25802755') format('woff'),
       url('../font/dimigoincon.ttf?25802755') format('truetype'),
       url('../font/dimigoincon.svg?25802755#dimigoincon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'dimigoincon';
    src: url('../font/dimigoincon.svg?25802755#dimigoincon') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "dimigoincon";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-alert:before { content: '\e800'; } /* '' */
.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-left:before { content: '\e802'; } /* '' */
.icon-arrow-right:before { content: '\e803'; } /* '' */
.icon-arrow-up:before { content: '\e804'; } /* '' */
.icon-ball:before { content: '\e805'; } /* '' */
.icon-book-sm:before { content: '\e806'; } /* '' */
.icon-clip:before { content: '\e807'; } /* '' */
.icon-club-sm:before { content: '\e808'; } /* '' */
.icon-comment:before { content: '\e809'; } /* '' */
.icon-counsel:before { content: '\e80a'; } /* '' */
.icon-cross:before { content: '\e80b'; } /* '' */
.icon-delete:before { content: '\e80c'; } /* '' */
.icon-detail:before { content: '\e80d'; } /* '' */
.icon-dets-sm:before { content: '\e80e'; } /* '' */
.icon-edit:before { content: '\e80f'; } /* '' */
.icon-editor:before { content: '\e810'; } /* '' */
.icon-email:before { content: '\e811'; } /* '' */
.icon-facebook-sm:before { content: '\e812'; } /* '' */
.icon-hourglass:before { content: '\e813'; } /* '' */
.icon-internet-class:before { content: '\e814'; } /* '' */
.icon-list:before { content: '\e815'; } /* '' */
.icon-logout:before { content: '\e816'; } /* '' */
.icon-long-arrow-down:before { content: '\e817'; } /* '' */
.icon-long-arrow-left:before { content: '\e818'; } /* '' */
.icon-long-arrow-right:before { content: '\e819'; } /* '' */
.icon-long-arrow-up:before { content: '\e81a'; } /* '' */
.icon-menu:before { content: '\e81b'; } /* '' */
.icon-minus:before { content: '\e81c'; } /* '' */
.icon-moon:before { content: '\e81d'; } /* '' */
.icon-muffin:before { content: '\e81e'; } /* '' */
.icon-notice:before { content: '\e81f'; } /* '' */
.icon-ok-circle:before { content: '\e820'; } /* '' */
.icon-ok:before { content: '\e821'; } /* '' */
.icon-plus:before { content: '\e822'; } /* '' */
.icon-profile:before { content: '\e823'; } /* '' */
.icon-request-sm:before { content: '\e824'; } /* '' */
.icon-school-meal:before { content: '\e825'; } /* '' */
.icon-search:before { content: '\e826'; } /* '' */
.icon-service:before { content: '\e827'; } /* '' */
.icon-setting:before { content: '\e828'; } /* '' */
.icon-sun:before { content: '\e829'; } /* '' */
.icon-upload:before { content: '\e82a'; } /* '' */
.icon-bamboo:before { content: '\e82b'; } /* '' */
.icon-book-lg:before { content: '\e82c'; } /* '' */
.icon-cart:before { content: '\e82d'; } /* '' */
.icon-club-lg:before { content: '\e82e'; } /* '' */
.icon-dets-lg:before { content: '\e82f'; } /* '' */
.icon-edison:before { content: '\e830'; } /* '' */
.icon-facebook-lg:before { content: '\e831'; } /* '' */
.icon-outgo:before { content: '\e832'; } /* '' */
.icon-request:before { content: '\e833'; } /* '' */
.icon-research:before { content: '\e834'; } /* '' */
.icon-setting-lg:before { content: '\e835'; } /* '' */
.icon-social:before { content: '\e836'; } /* '' */
.icon-submission:before { content: '\e837'; } /* '' */
.icon-dimicat:before { content: '\e838'; } /* '' */
.icon-dimigo:before { content: '\e839'; } /* '' */
.icon-newspaper:before { content: '\e83a'; } /* '' */
.icon-betweenfox:before { content: '\e83b'; } /* '' */
.icon-benedu:before { content: '\e83c'; } /* '' */