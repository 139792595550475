/* 나눔스퀘어라운드 웹폰트 v1.11 https://github.com/ChalkPE/NanumSquareRound */
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 300;
  src: local("NanumSquareRoundL"), /* computer */
    url("NanumSquareRoundL.woff2") format("woff2"), /* Modern Browsers */
    url("NanumSquareRoundL.woff") format("woff"), /* Modern Browsers */
    url("NanumSquareRoundL.ttf") format("truetype"), /* Safari, Android, iOS */
    url("NanumSquareRoundL.eot"), /* IE9 Compat Modes */
    url("NanumSquareRoundL.eot?#iefix") format("embedded-opentype"); /* IE6-IE8 */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 400;
  src: local("NanumSquareRoundR"), /* computer */
    url("NanumSquareRoundR.woff2") format("woff2"), /* Modern Browsers */
    url("NanumSquareRoundR.woff") format("woff"), /* Modern Browsers */
    url("NanumSquareRoundR.ttf") format("truetype"), /* Safari, Android, iOS */
    url("NanumSquareRoundR.eot"), /* IE9 Compat Modes */
    url("NanumSquareRoundR.eot?#iefix") format("embedded-opentype"); /* IE6-IE8 */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 700;
  src: local("NanumSquareRoundB"), /* computer */
    url("NanumSquareRoundB.woff2") format("woff2"), /* Modern Browsers */
    url("NanumSquareRoundB.woff") format("woff"), /* Modern Browsers */
    url("NanumSquareRoundB.ttf") format("truetype"), /* Safari, Android, iOS */
    url("NanumSquareRoundB.eot"), /* IE9 Compat Modes */
    url("NanumSquareRoundB.eot?#iefix") format("embedded-opentype"); /* IE6-IE8 */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 800;
  src: local("NanumSquareRoundEB"), /* computer */
    url("NanumSquareRoundEB.woff2") format("woff2"), /* Modern Browsers */
    url("NanumSquareRoundEB.woff") format("woff"), /* Modern Browsers */
    url("NanumSquareRoundEB.ttf") format("truetype"), /* Safari, Android, iOS */
    url("NanumSquareRoundEB.eot"), /* IE9 Compat Modes */
    url("NanumSquareRoundEB.eot?#iefix") format("embedded-opentype"); /* IE6-IE8 */
}
